/* src/components/menuCalendar.css */
.menu-calendar {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px; /* Prevents it from growing too wide */
  box-sizing: border-box;
}

.carousel-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5em;
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1;
}

.carousel-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  gap: 10px;
}

.nav-button {
  background-color: #6f6f6f;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 1.2em;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #03586d;
}

.nav-button.prev {
  margin-right: 20px;
}

.nav-button.next {
  margin-left: 20px;
}

.week-section {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  overflow-x: auto; /* Enables horizontal scrolling if needed */
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 days: Måndag–Fredag */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-width: 0; /* Prevents overflow */
}

.day-column {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  min-width: 0; /* Allows shrinking */
}

.day-column h3 {
  color: #333;
  font-size: 1.1em;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
}

.meal-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.meal-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 12px;
}

.meal-number {
  color: #666;
  font-weight: bold;
  margin-right: 10px;
  width: 20px;
  flex-shrink: 0;
}

.meal-text {
  flex-grow: 1;
  color: #222;
  text-align: center;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meal-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0; /* Prevents overflow */
}

.dish-wrapper {
  display: flex;
  align-items: center;
}

.meal-side {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-top: 4px;
  color: #666;
}

.check-icon {
  color: #4CAF50;
  font-size: 1.2em;
  font-weight: bold;
}

.tagIcon {
  width: 18px;
}

.dish-icon {
  margin-right: 8px;
  width: 16px;
}

.dish-icon.vegetarian {
  color: #2ecc71;
}

.dish-icon.chicken {
  color: #e67e22;
}

.dish-icon.meat {
  color: #c0392b;
}

.print-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #ffffff00;
  color: rgb(8, 99, 142);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.print-button:hover {
  background-color: #cdcdcd;
}

.print-button svg {
  margin-right: 5px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .calendar-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adaptive columns */
  }
}

@media (max-width: 768px) {
  .calendar-grid {
    grid-template-columns: 1fr; /* Stack columns */
    gap: 15px;
  }

  .day-column {
    width: 100%;
  }

  .carousel-header {
    flex-direction: column;
    align-items: center;
  }

  .nav-button.prev {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .nav-button.next {
    margin-top: 10px;
    margin-left: 0;
  }

  .carousel-title {
    display: block;
    margin: 10px 0;
  }

  .week-section {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .meal-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .meal-number {
    margin-bottom: 5px;
  }

  .meal-text,
  .meal-side {
    white-space: normal; /* Allow wrapping */
    max-width: none; /* Remove max-width restriction */
  }

  .menu-calendar {
    margin: 10px;
    padding: 10px;
  }

  .carousel-title {
    font-size: 1.2em;
  }
}

/* Hover Effects */
.meal-text:hover,
.meal-side:hover {
  overflow: visible;
  white-space: normal;
  background-color: #f8f9fa;
  z-index: 1;
  position: relative;
}