/* App.css */

/* Övergripande stil */
.App {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  /* Veckokontroller */
  .controls {
    text-align: center;
  }
  
  /* Inställningsgrupp för cykel */
  .input-group {
    display: flex;
    gap: 20px;
    // align-items: center;
    justify-content: flex-start;
  }
  
  .input-group label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: white;
  }
  
  .input-group input[type="text"],
  .input-group input[type="number"] {
    padding: 5px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  /* Kalendercontainer */
  .calendar {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Varje vecka renderas som en rad med två kolumner
     (vänster: veckokalender, höger: maträtts-menyn om aktiv) */
  .week-row {
    display: grid;
    grid-template-columns: 3fr 1fr; /* justera för att ändra bredden */
    gap: 20px;
    align-items: start;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    cursor: pointer; /* klicka för att aktivera veckan */
  }
  
  .week-row.active {
    /* Exempel: markera den aktiva veckan med en annan bakgrundsfärg */
    background-color: #e7f4ff;
  }
  
  /* Veckokalenderns stil */
  .week h2 {
    margin: 0 0 10px;
    text-align: center;
  }
  
/* Ensure all days align properly */
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  align-items: start; /* Ensures cells align at the top */
}
/* Enskild dagcell (Ensuring consistent height) */
.day-cell {
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #fff;
  min-height: 250px;
  height: 100%; /* Ensure full height */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push the dropdown to the bottom */
  align-items: center;
}
/* Align the meal category text (Lunch, Middag) */
.admin-meal-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content at the top */
  align-items: center;
  flex-grow: 1; /* Ensures all meal sections have the same height */
  min-height: 50px; /* Prevents text shifting when cells have different content */
}
select.form-control {
  margin-top: auto; /* Push to the bottom */
  width: 100%; /* Optional: Make it full width */
}
/* Ensuring "Middag" aligns with "Lunch" */
.admin-meal-section strong {
  display: block;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  min-height: 20px; /* Ensures same height for meal titles */
  display: flex;
  align-items: center; /* Centers the text properly */
}
.admin-meal-text {
  flex-grow: 1;
  color: #222;
  text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Ensuring Super (Middag) cells are aligned */
.drop-zone {
  border: 1px dashed #aaa;
  padding: 5px;
  text-align: center;
  margin: 10px 0 10px 0;
  min-height: 200px; /* Same height for all drop zones */
  background-color: #eaeaea;
  border-radius: 4px;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Keep content at the top */
  align-items: center;
  width: 100%; /* Ensures consistent width */
  min-width: 100px;
}

.drop-zone:hover {
  background-color: #d5d5d5;
}
  
  /* Maträtts-menyn (visas endast i den aktiva vecka-raden) */
  .dishes-menu {
    position: sticky; /* Keep dishes menu fixed on scroll */
    top: 20px;
    max-height: 80vh; /* Limit height with scroll */
    overflow-y: auto; /* Vertical scroll for dishes */
    padding: 10px;
    background: #fff; /* Optional: contrast */
    border-left: 1px solid #ccc; /* Optional: separation */
  }
  
  .dishes-menu h2 {
    text-align: center;
    margin-top: 0;
  }
  
/* Align dish items properly */
.dish-item {
  border: 1px solid #aaa;
  padding: 8px;
  margin-bottom: 4px;
  background-color: #f1f1f1;
  text-align: center;
  cursor: grab;
  border-radius: 4px;
  user-select: none;
  transition: background-color 0.2s;
  width: 100%; /* Consistent width */
  box-sizing: border-box;
}

.dish-item:hover {
  background-color: #e1e1e1;
}

.side-dish-list {
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
}

.side-dish-list li {
  background: #f1f1f1;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 2px;
  font-size: 14px;
  text-align: center;
}